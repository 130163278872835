import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "hero_banner_people.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Home" />
      <div className="row hero-container">
        <div className="col">
          <Img fluid={data.logo.childImageSharp.fluid} alt="BizHelp Miami" />
        </div>
      </div>
      <div className="container primary-bg">
        <div className="row">
          <div className="col">
            <h1 className="small-padding">
              We All Need Extra Help These Days,
            </h1>
            <p className="small-padding">
              especially the small businesses trying to navigate the many
              programs and resources rolled out to support them and their staff
              during COVID-19.
            </p>
            <h1 className="small-padding">Welcome to BizHelp.Miami</h1>
            <p className="small-padding">
              We are here to help you better understand and successfully access
              the funding resources available to Miami-Dade small businesses,
              sole proprietors and independent contractors looking for relief
              from the impact of the Coronavirus.
            </p>
            <p className="small-padding">
              Federal funding programs – including the Paycheck Protection Program (PPP) and the Emergency Injury Disaster Loans (EIDL)– have received additional funding. We encourage everyone to submit their application as quickly as possible.
            </p>
            <ul>
              <li>
              Reimbursement Grants of up to $10,000 Available via CareerSource South Florida's COVID-19 Layoff Aversion Fund. Learn More & Apply Today!
              </li>
              <li>
              Register here and review PPP funding requirements and connect with an approved lender
              </li>
              <li>
              Explore alternative funding options via foundations and other industry-specific or local organizations
              </li>
              <li>
              Connect directly with a network of qualified advisors at local organizations that are ready to help guide you through this process
              </li>
              <li>
              Find the technical assistance you need to address your business needs in today’s climate
              </li>
            </ul>
            <Link className="full-cta" to="/additional-funding-foundations-grants-nonprofits">
              Reimbursement Grants of up to $10,000 Available via CareerSource South Florida's COVID-19 Layoff Aversion Fund. Learn More & Apply Today!
            </Link>
            <a
              className="full-cta"
              href="https://miamitech.works/"
              target="_blank"
            >
              Click here to explore MiamiTech.Works – our 1-stop portal for Tech Job & Reskilling
            </a>
            <a
              className="full-cta"
              href="https://www.beaconcouncil.com/wp-content/uploads/2020/04/FAQ_MDBC_PAYCHECK-PROTECTION-PROGRAM_04.08.2020.pdf"
              target="_blank"
            >
              FAQ
            </a>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
